import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

const CardContainer = styled.div`
  padding: 1rem;
  width: ${({ size }) => (size === "big" ? "50%" : "25%")};

  > :link,
  > :visited {
    text-decoration: none;
    color: #000;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`

const Card = styled.div`
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.05);
  transition: all 150ms ease-in-out;

  :hover {
    box-shadow: 0px 9px 24px rgb(0 0 0 / 10%);
    cursor: pointer;
  }
`

const TextContainer = styled.div`
  padding: 0.5rem;
  margin: 1rem;
`

const TitleCard = styled.h3`
  font-size: ${({ size }) => (size === "big" ? "1.5rem" : "1rem")};
  font-weight: 700;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > :link,
  > :visited {
    text-decoration: none;
    color: #000;
  }
`
const SubtitleCard = styled.h4`
  font-size: ${({ size }) => (size === "big" ? "0.75rem" : "0.65rem")};
  font-weight: 400;
  margin-bottom: 0.75rem;
  color: #73808d;
  text-decoration: none;

  > :link,
  > :visited {
    text-decoration: none;
    color: #000;
  }
`

const ProjectCard = ({ project, size = "big" }) => {
  const { title, company, year, thumbnail, slug } = project

  return (
    <CardContainer size={size}>
      <Link to={`/projects/${slug}`}>
        <Card>
          <GatsbyImage
            image={getImage(thumbnail)}
            alt={`${title} thumbnail`}
            style={{ borderRadius: "8px 8px 0px 0px", width: "100%" }}
          />
          <TextContainer>
            <TitleCard> {title} </TitleCard>
            <SubtitleCard>
              {" "}
              {company} - {year}{" "}
            </SubtitleCard>
          </TextContainer>
        </Card>
      </Link>
    </CardContainer>
  )
}

export default ProjectCard
