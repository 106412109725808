import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectCard from "../components/projectCard"

const Top = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 576px) {
    flex-direction: column-reverse;
    padding-top: 0;
  }

  @media only screen and (max-width: 576px) {
    padding: 0rem;
  }
`
const TopText = styled.div`
  width: 45%;
  padding-right: 0.75rem;

  @media only screen and (max-width: 576px) {
    width: 100%;
    padding-top: 2rem;
  }
`
const Title = styled.h2`
  @media only screen and (max-width: 576px) {
    text-align: center;
  }
`
const Subtitle = styled.h4`
  @media only screen and (max-width: 576px) {
    text-align: center;
  }
`
const BodyText = styled.div`
  font-size: 0.875rem;
`
const TopImage = styled.div`
  width: 45%;
  @media only screen and (max-width: 576px) {
    width: 100vw;
    margin: -4rem -1.0875rem 0 -1.0875rem;
  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
      max-height: 50%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    padding-left: 0.75rem;
  }
`
const SmallImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
`
const SmallImageTile = styled.div`
  width: 100%;
  padding-bottom: 1.5rem;
`

const MoreProjectsContainer = {
  Wrapper: styled.div``,
  CardsContainer: styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  `,
}

const ProjectPage = ({
  data: {
    markdownRemark: project,
    moreProjects: { edges: moreProjects },
  },
}) => {
  const { html } = project
  const { year, title, company, thumbnail, images } = project.frontmatter || {}
  const mainImg = getImage(thumbnail)

  return (
    <Layout>
      <Seo title={title} />
      <Top>
        <TopText>
          <Title>{title}</Title>
          <Subtitle>
            {company} - {year}
          </Subtitle>
          <BodyText dangerouslySetInnerHTML={{ __html: html }}></BodyText>
        </TopText>
        <TopImage>
          <GatsbyImage
            image={mainImg}
            alt={`${title} main image`}
            style={{ height: "100%", width: "100%" }}
          />
        </TopImage>
      </Top>

      <SmallImagesContainer>
        {images.map(img => {
          const fetchedImg = getImage(img)
          const style = { width: "100%", height: "100%", borderRadius: "6px" }
          return (
            <SmallImageTile key={`${img.publicURL}`}>
              {fetchedImg ? (
                <GatsbyImage image={fetchedImg} alt={img.name} style={style} />
              ) : (
                <img src={img.publicURL} alt={img.name} style={style} />
              )}
            </SmallImageTile>
          )
        })}
      </SmallImagesContainer>

      <MoreProjectsContainer.Wrapper>
        <Title style={{ marginLeft: "1rem" }}>More projects</Title>
        <MoreProjectsContainer.CardsContainer>
          {moreProjects.map(({ node: { frontmatter } }) => (
            <ProjectCard
              project={frontmatter}
              size="small"
              key={frontmatter.title}
            />
          ))}
        </MoreProjectsContainer.CardsContainer>
      </MoreProjectsContainer.Wrapper>
    </Layout>
  )
}

export default ProjectPage
export const pageQuery = graphql`
  query projectByID($id: String!, $moreProjectsIDs: [String]!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        year
        title
        company
        slug
        thumbnail {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
          publicURL
          name
        }
      }
    }

    moreProjects: allMarkdownRemark(filter: { id: { in: $moreProjectsIDs } }) {
      edges {
        node {
          frontmatter {
            year
            title
            company
            slug
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
